<template>
  <transition :css="fixed" name="medialibrary-slide">
    <div class="medialibrary__sidebar" id="teleportTo" :class="{ 'medialibrary__sidebar--not-fixed': !fixed }"
      v-on-clickaway="fixed ? away : () => { }" v-if="l_show">
      <Dropzone :busy="false" @click.self="active_asset = null" :on_change="(files) => create_assets(files)"
        v-slot="dropzone">

        <!--main menu starts here-->
        <div class="media_lib__navbar">
          <div class="button__tab_group">
            <div class="button__tab" :class="{ 'button__tab--active': tab == 'assets' }"
              v-on:click="tab === 'assets' ? tab = null : tab = 'assets'">
              <i class="fa-regular fa-download"></i>
              My media
            </div>
            <div class="button__tab" :class="{ 'button__tab--active': tab == 'feed' }" v-on:click="tab = 'feed'">
              <i class="fa-regular fa-download"></i>
              Feed
            </div>
            <div class="button__tab" v-on:click="tab = 'custom'" :class="{ 'button__tab--active': tab == 'custom' }"
              v-if="$slots.custom_tab_selector">
              <slot name="custom_tab_selector"></slot>
            </div>
            <div class="button__tab" v-if="custom_feed" :class="{ 'button__tab--active': tab == custom_feed_name }"
              v-on:click="tab = custom_feed_name">
              <i class="fa-regular fa-upload"></i>
              Briefing
            </div>
            <div class="loader" v-if="loading"></div>
          </div>
          <div style="display:flex;">
            <slot name="extra_actions"></slot>
          </div>
        </div>

        <!--submenu starts here-->
        <span v-if="tab == 'custom'">
          <slot name="custom_tab_content" v-bind:pick_asset="pick_asset"></slot>
        </span>

        <div class="media_lib__body" v-if="tab == custom_feed_name && custom_feed">
          <div class="media_lib__body__navbar">
            <div v-for="(asset, index) in custom_feed.data" :key="`custom_feed_asset_${index}`"
              class="medialibrary__asset-container">
              <div class="medialibrary__asset">
                <span v-if="asset.data.asset_url ? !asset.data.asset_url.toLowerCase().includes('mp4') : false">
                  <img :src="asset.data.asset_url" :id="`asset_${asset.data.asset_sid}`">
                </span>

                <span v-else>
                  <Video :url="asset.data.image_link" :poster="asset.data.thumbnail_url"></Video>
                  <div class="medialibrary__vid-icon">
                    <i class="fa-regular fa-video"></i>
                  </div>
                </span>
                <span v-if="asset.data.title">
                  {{ asset.data.title.substring(0, 10) }}...
                </span>
                <div class="medialibrary__asset-buttons">
                  <button v-if="on_asset" v-on:click.self="e => pick_asset({
    ...asset.data,
    asset_url: asset.data.image_link
  })">Use</button>
                </div>
                
                <div class="medialibrary__asset-action">
                  <button class="button--dark" v-on:click="delete_feed_row(asset)">
                    <i class="fa-regular fa-trash"></i>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>

        <Transition name="fade">
          <div class="media_lib__body" v-if="tab == 'assets' && show_body">

            <div class="media_lib__body__navbar">
              <div class="media_lib__body__navbar__labels" v-if="show_filters">
                <div class="button__toggle__light" v-on:click="filters = []"
                  :class="{ 'button__toggle--active': filters.length == 0 }">
                  All
                </div>
                <div class="button__toggle__light"
                  :class="{ 'button__toggle--active': filters.includes(label) }" v-on:click="e => toggle_filter(label)"
                  v-for="label in Object.keys(folder.labels)" :key="label">{{ label }} ({{ folder.labels[label] }})
                </div>
              </div>

              <div v-if="upload_from_phone_active" style="text-align: center;">
                <img :src="public_qr_code" style="width: 100%; max-width: 200px; margin: 12px auto; display: block;">
                <h3 class="smaller">Scan this QR code with your phone and upload your media</h3>
              </div>

              <div class="media_lib__body__navbar__labels">

                <input type="text" placeholder="Search..." v-model="search" ref="search">
                <div style="display: flex; gap: 8px">
                  <div class="button button--light" v-on:click="manual_upload">
                    Upload
                    <!--<i class="fa-regular fa-cloud-arrow-up"></i>-->
                  </div>
                  <!-- <div v-on:click="upload_from_phone_active = !upload_from_phone_active"
                    class="button button--light button--dark-text"
                    :style="`${upload_from_phone_active ? 'background: #1E86FF; color: white;' : null}`">
                    Upload media from phone
                  </div> -->
                </div>
                <input type="file" multiple accept="image/*, video/*" style="display: none;" ref="manual_upload"
                  @change="manual_upload_files" />

              </div>
            </div>

            <!--media lib starts here-->
            <span v-if="!active_asset || (active_asset ? active_asset.content_type == 'image' : true)"
              class="medialibrary__category-container">

              <div v-for="(asset, index) in active_uploads" :key="`ap_${index}`" class="medialibrary__asset-container">
                <div class="medialibrary__asset">
                  <img :src="asset.url" v-if="asset.type == 'image'">
                  <div class="medialibrary__loader-holder">
                    {{ asset.progress }}%
                  </div>
                </div>
              </div>
              <div v-if="assets.length > 0">
                <div v-for="(asset, index) in assets" :key="`asset_${index}_${asset.id}`"
                  class="medialibrary__asset-container">

                  <div class="medialibrary__asset" style="margin: 5px; border-radius: 5px; overflow: hidden;"
                    :class="{ 'medialibrary__asset--selected': is_selected(asset) }"
                    v-on:click="e => select_asset(asset)">
                    <span v-if="asset.content_type == 'image'">
                      <img :src="asset.file_url" :id="`asset_${asset.sid}`" v-if="asset.content_type == 'image'">
                      <div v-if="has_focus_points(asset)" class="medialibrary__asset-focus-label">Focussed</div>
                      <div v-if="asset.processing" class="medialibrary__loader-holder">
                        {{ asset.msg }} <div class="loader"></div>
                      </div>
                    </span>

                    <span v-if="asset.content_type == 'video'">
                      <Video :url="asset.file_url" :poster="asset.thumbnail_url"></Video>
                      <div class="medialibrary__asset-focus-label" v-if="is_split(asset)">
                        {{ asset.feed_element['scenes'].length }} clips</div>
                      <div v-if="asset.content_type == 'video'" class="medialibrary__vid-icon">
                        <i class="fa-regular fa-video"></i>
                      </div>
                      <div v-if="asset.processing" class="medialibrary__loader-holder">
                        {{ asset.msg }} <div class="loader"></div>
                      </div>
                    </span>
                  </div>

                  <div class="medialibrary__asset-buttons">
                  </div>
                  

                  <div class="medialibrary__asset-action">
                    <Panel :panelTitle="'Actions'" position="bottom-right" :margin="10">
                      <template #button>
                        <button class="button button--dark">
                          <i class="fa-solid fa-square-ellipsis-vertical"></i>
                        </button>
                      </template>

                      <!-- Knop voor afbeeldingen om focuspunt te selecteren -->
                      <button class="button button--light" 
                              v-if="asset.content_type === 'image' && !asset.processing" 
                              v-on:click.stop="active_asset = asset">
                        <i class="fa-regular fa-arrows-to-circle"></i> Set focuspoint
                      </button>

                      <!-- Knop voor video's om een scene te selecteren -->
                      <button class="button button--light" 
                              v-if="asset.content_type === 'video' && !asset.processing" 
                              v-on:click.stop="active_asset = asset">
                        <i class="fa-regular fa-video"></i> Select scene
                      </button>

                      <!-- Delete knop die altijd zichtbaar is -->
                      <button class="button button--light" 
                              v-if="!asset.processing" 
                              v-on:click="delete_asset(asset)">
                        <i class="fa-regular fa-trash"></i> Delete
                      </button>
                    </Panel>
                  </div>

                </div>
                <br><br>
                <button class="button button--light" :disabled="loading" v-on:click="load_more_assets">
                  Load more
                </button>
              </div>
              <div v-else>
                <slot name="virgin"></slot>
              </div>

            </span>
            <span v-else>
              <button v-on:click="active_asset = null">Back</button>
              <label>{{ active_asset.feed_element['scenes'].length }} clips</label>
              <hr />
              <div class="medialibrary__asset-container" style="width: 100%;">
                <div class="medialibrary__asset-scene-list">
                  <div v-for="(scene, index) in active_asset.feed_element['scenes']" :key="`ml_scene_${index}`"
                    class="medialibrary__asset-scene-container">
                    <div class="medialibrary__asset-scene-video">
                      <Video :url="active_asset.file_url" :start="scene[0]" :end="scene[1]"></Video>
                    </div>
                    <div class="medialibrary__asset-scene-actions">
                      Duration: {{ (parseFloat(scene[1]) - parseFloat(scene[0])).toFixed(2) }}s
                      <button v-if="on_asset" v-on:click.self="e => pick_asset({
    ...active_asset.feed_element,
    image_link: `${active_asset.file_url}#t=${scene[0]},${scene[1]}`,
  })">Use</button>
                    </div>
                  </div>
                </div>
              </div>
            </span>


            <FocusPicker v-if="active_asset && active_asset.content_type == 'image'"
              :on_close="e => active_asset = null" :url="get_focus_url(active_asset)" :show="active_asset !== null"
              :on_pick="(e) => on_focus_pick(active_asset, e)" />

          </div>

          <div class="media_lib__body" v-if="tab == 'feed'">
            <div class="media_lib__body__navbar">
              <FeedSelector :on_feed_select="set_feed" style="width: 200px" />
              <input type="text" placeholder="Search..." v-model="search" ref="search">
            </div>

            <div class="medialibrary__category-container" v-if="active_product == null">
              <div v-for="product in feed_search_results" :key="`product_${product.id}`"
                class="medialibrary__asset-container" v-on:click="e => pick_asset({
    ...product,
    asset_url: product.image_link
  })">
                <div class="medialibrary__asset">
                  <img :src="product.image_link" :id="`product_${product.id}`">
                  <div v-if="product.additional_image_link ? product.additional_image_link.length > 0 : false"
                    class="medialibrary__asset-focus-label">{{ product.additional_image_link.length }} images</div>
                  <div v-if="product.adflow_image_classification == 'lifestyle'" class="medialibrary__asset-focus-label"
                    style="top: 25px; background: #FFA383;">Lifestyle</div>
                  {{ product.title.substring(0, 10) }}...
                  <div class="medialibrary__asset-buttons">
                  </div>
                  <div class="medialibrary__asset-action">
                    <button class="button--dark" v-on:click="active_product = product">
                      <i class="fa-regular fa-images"></i>
                    </button>
                  </div>
                </div>
              </div>
              <br><br>
              <button class="button--dark" v-on:click="load_more_products" :disabled="feed_total_pages === feed_page">
                more ({{ feed_page }}/{{ feed_total_pages }})
              </button>
            </div>

            <span v-else>
              <div class="tweener">
                <b>{{ active_product.title }}</b>
                <button v-on:click="active_product = null">Back</button>
              </div>
              <br>
              <hr>
              <div class="medialibrary__asset-container">
                <div class="medialibrary__asset">
                  <img :src="active_product.image_link" :id="`product_${active_product.id}`">
                </div>
                <div class="medialibrary__asset-buttons">
                  <button v-if="on_asset" v-on:click.self="e => pick_asset({
    ...active_product,
    asset_url: image_link
  })">Use</button>
                </div>
              </div>
              <div class="medialibrary__asset-container" :key="`additional_image_${index}`"
                v-for="(image_link, index) in active_product.additional_image_link">
                <div class="medialibrary__asset">
                  <img :src="image_link" :id="`add_image_${index}`">
                </div>
                <div class="medialibrary__asset-buttons">
                  <button v-if="on_asset" v-on:click.self="e => pick_asset({
    ...active_product,
    asset_url: image_link,
    image_link: image_link
  })">Use</button>
                </div>

              </div>
            </span>

          </div>
        </Transition>
        <slot name="footer"></slot>
      </Dropzone>

    </div>
  </transition>
</template>
<script>
import api from '../store/api'
import Panel from './panel.vue'
import Teleport from 'vue2-teleport'
import FocusPicker from '../../assets/focuspicker.vue'
import Dropzone from '../dropzone.vue'
import axios from 'axios';
import { MediaFolderChannel } from '../../lib/consumers/media_folder.js';
import { mixin as clickaway } from 'vue-clickaway2'
import { debounce } from "debounce";
import Video from './video.vue'
import FeedSelector from './feed_selector.vue'
import { EventBus } from '../../lib/media/eventbus.js'
import QRCode from 'qrcode'
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
export default {
  mixins: [clickaway],
  components: {
    Video,
    FocusPicker,
    Dropzone,
    FeedSelector,
    Panel,
    Teleport,
  },
  props: {
    fixed: {
      type: Boolean,
      default: true
    },
    custom_feed: {
      type: Object,
      required: false,
      default: null
    },
    custom_feed_name: {
      type: String,
      required: false,
      default: 'custom'
    },
    on_asset: {
      type: Function,
      required: false,
      default: () => { }
    },
    show: {
      type: Boolean,
      default: false
    },
    on_close: {
      type: Function,
      required: false,
      default: () => { }
    },
    on_open: {
      type: Function,
      required: false,
      default: () => { }
    },
    select_on_upload: {
      type: Boolean,
      default: false
    },
    show_filters: {
      type: Boolean,
      default: true
    },
    default_tab: {
      type: String,
      default: 'assets'
    }
  },
  computed: {
    assets() {
      let assets = []
      if (this.filters.length === 0) {
        assets = this.folder.assets
      } else {
        assets = this.folder.assets.filter(asset => {
          return this.filters.some(filter => asset.labels.includes(filter))
        })
      }
      if (this.$props.only_show_selected) {
        assets = [
          ...assets.filter(asset => asset.processing).map(a => ({ ...a.feed_element, file_url: a.feed_element.asset_url })),
          ...this.selected_assets.map(a => ({ ...a, file_url: a.asset_url }))]
      }
      return assets
    }
  },
  data() {
    return {
      loading: true,
      tab: this.$props.default_tab,
      folder: {
        assets: [],
        labels: {}
      },
      search: "",
      videos: [],
      images: [],
      selected: [],
      filters: [],
      active_uploads: [],
      active_asset: null,
      active_product: null,
      l_show: false,
      assets_page: 1,
      show_body: true,
      field_id: null,
      feed_search_results: [],
      feed_page: 1,
      feed_total_pages: 1,
      feed_id: null,
      public_qr_code: null,
      upload_from_phone_active: false
    }
  },
  watch: {
    default_tab: {
      immediate: true,
      handler: function (val) {
        this.tab = val
      }
    },
    tab: {
      immediate: true,
      handler: function (val) {
        if (val === null) {
          this.show_body = false
        } else {
          this.show_body = true
        }
        if (val == 'feed' && this.feed_search_results.length == 0) {
          this.debounced_search("")
        }
      }
    },
    show: {
      immediate: true,
      handler: async function (val) {
        this.l_show = val
        if (val === true) {
          this.loading = true
          this.assets_page = 1
          const folder = await api.get_media_folder()
          this.folder = folder
          this.loading = false
        }
      }
    },
    search: {
      handler: function (val) {
        this.feed_page = 1
        this.debounced_search(val)
      }
    }
  },
  async mounted() {
    const folder = await api.get_media_folder()
    this.folder = folder
    this.public_qr_code = await QRCode.toDataURL(`${window.location.origin}/public/user_media_folders_upload/${this.folder.shared_secret}`)
    this.loading = false
    let _self = this
    EventBus.$emit('ready', {})
    EventBus.$on('show', (e) => {
      this.l_show = true
      this.on_open()
      if (e && e.field_id)
        this.field_id = e.field_id
      if (e && e.tab) {
        this.tab = e.tab
      }
      setTimeout(() => {
        this.$refs.search.focus()
      }, 15)
      EventBus.$emit('open', {})
    })

    EventBus.$on('hide', (e) => {
      this.l_show = false
      this.field_id = null
      this.on_close()
      EventBus.$emit('closed', {})
    })

    EventBus.$on('upload', async (e) => {
      EventBus.$emit('upload_in_progress', { completion: 0, msg: null })
      await this.create_assets([e.file])
      EventBus.$emit('upload_finished', {})
    })

    MediaFolderChannel.onReceive(data => {
      _self.folder.labels = data.labels
      if (this.folder.assets.filter(a => a.sid === data.asset.sid).length === 0 && data.event === 'asset_processing') {
        // _self.folder.assets = [data.asset, ...this.folder.assets]
      }
      _self.folder.assets = this.folder.assets.map(asset => {
        if (asset.sid === data.asset.sid) {
          asset.feed_element = data.asset.feed_element
          asset.labels = data.asset.labels
          if (data.event == 'asset_processing') {
            asset.processing = true
            asset.msg = data.msg
            EventBus.$emit('asset_processing', { completion: 100, msg: data.msg, asset })
          } else if (data.event == 'asset_processed') {
            asset.processing = false
            asset.file_url = data.asset.file_url
            EventBus.$emit('asset_processed', { completion: 100, msg: data.msg, asset })
            if (_self.select_on_upload) {
              _self.pick_asset(asset.feed_element)
            }
          }
        }
        return asset
      })
      _self.$forceUpdate()
    })
  },
  methods: {
    set_feed(e) {
      this.feed_id = e.target.value
      this.feed_page = 1
      this.debounced_search("")
    },
    async manual_upload_files(e) {
      await this.create_assets([...this.$refs.manual_upload.files])
    },
    manual_upload() {
      this.$refs.manual_upload.click()
    },
    away() {
      this.l_show = false
    },
    load_more_products() {
      this.feed_page = this.feed_page + 1
      this.debounced_search(this.search, (results) => {
        this.feed_search_results = this.feed_search_results.concat(results)
      })
    },
    load_more_assets() {
      this.assets_page = this.assets_page + 1
      this.debounced_search(this.search, (results) => {
        this.folder.assets = this.folder.assets.concat(results)
      })
    },
    pick_asset(element) {
      if (typeof element.adflow_classification_map === 'object' && element.adflow_classification_map instanceof Array) {
        let map = element.adflow_classification_map.map(el => {
          if (el[0] === element.asset_url)
            return el
        }).filter(Boolean).flat()
        try {
          element = {
            ...element,
            adflow_image_classification: map.pop().pop()
          }
        } catch (e) {
          console.error(e)
        }
      }
      if (element.asset_url.toLowerCase().includes('mp4')) {
        element.adflow_image_classification = 'lifestyle'
      }
      if (this.on_asset && !this.field_id)
        this.on_asset(element)
      EventBus.$emit('on_asset_' + this.field_id, element)
      EventBus.$emit('asset_picked', element)
    },
    debounced_search: debounce(async function (str, callback) {
      this.loading = true
      if (this.tab === 'assets') {
        const assets = await axios.get(`${window.location.origin}/user_media_folder/assets.json?search=${str}&page=${this.assets_page}`)
        if (callback) {
          callback(assets.data)
        } else {
          this.folder.assets = assets.data.reverse()
        }
      } else {
        const conditions = [
          {
            op: 'and',
            children: str.split(' ').map(val => {
              return { op: 'contains', column: 'title', value: val }
            })
          },
          {
            op: 'or',
            children: [
              {
                op: 'contains',
                column: 'id',
                value: str
              },
              {
                op: 'contains',
                column: 'adflow_image_classification',
                value: str
              },
              {
                op: 'contains',
                column: 'link',
                value: str
              }
            ]
          }
        ]

        const result = await api.filter_feed({ feed_id: this.feed_id, conditions, gate: 'or', page: this.feed_page })
        this.feed_total_pages = result.data.total_pages
        const results = result.data.sample.map(s => {
          if (typeof s.additional_image_link === "string") {
            s.additional_image_link = [s.additional_image_link]
          }
          return s
        })
        if (callback) {
          callback(results)
        } else {
          this.feed_search_results = results
        }
      }
      this.loading = false
    }, 500),
    get_focus_url(asset) {
      let url = new URL(asset.file_url)
      let focus_x = url.searchParams.get('focus_x')
      let focus_y = url.searchParams.get('focus_y')
      if (asset.feed_element['focus']) {
        if (!focus_x)
          url.searchParams.append('focus_x', asset.feed_element['focus']['focus_x'])
        if (!focus_y)
          url.searchParams.append('focus_y', asset.feed_element['focus']['focus_y'])
      }
      return url.toString()
    },
    async on_focus_pick(asset, e) {
      let link = new URL(e)
      let focus_x = link.searchParams.get('focus_x')
      let focus_y = link.searchParams.get('focus_y')
      let focus = { focus_x, focus_y }
      this.folder.assets = this.folder.assets.map(a => {
        if (a.id === asset.id) {
          a.feed_element['focus'] = focus
        }
        return a
      })
      await this.update_asset(asset)
    },
    is_split(asset) {
      if (asset.feed_element['scenes']) {
        return asset.feed_element['scenes'].length > 1
      }
      return false
    },
    has_focus_points(asset) {
      return !!asset.feed_element['focus']
    },
    is_selected(asset) {
      return !!this.selected.find(a => a.id === asset.id)
    },
    select_asset(asset) {
      if (!asset.processing) {
        this.pick_asset({
          ...asset.feed_element,
          image_link: this.get_focus_url(asset),
        })
      }
    },
    async create_assets(files, opts = { external: false }) {
      await Promise.all(files.map(async (file, index) => {
        this.active_uploads.push({
          progress: 0,
          url: URL.createObjectURL(file),
          type: file.type.split('/')[0]
        })
        const asset = await api.upload_file(file, {
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent;
            const percentage = Math.floor((loaded * 100) / total)
            this.active_uploads[index].progress = percentage
            EventBus.$emit('upload_in_progress', { completion: percentage })
          }
        })
        EventBus.$emit('asset_uploaded', { completion: 100, asset, opts })
        this.folder.assets.unshift({ ...asset, processing: true })
      }))
      this.active_uploads = []
    },
    async delete_asset(asset) {
      const result = await axios.delete(`${window.location.origin}/user_media_folder/assets/${asset.sid}.json`)
      this.folder.assets = this.folder.assets.filter(a => a.id !== asset.id)
      return result
    },
    async delete_feed_row(row) {
      this.$store.dispatch('delete_asset', row.data)
    },
    async update_asset(asset) {
      const result = await axios.patch(`${window.location.origin}/user_media_folder/assets/${asset.sid}.json`, {
        feed_element: {
          ...asset.feed_element
        }
      })
      return result
    },
    toggle_filter(label) {
      this.active_asset = null
      this.filters = []
      this.filters.push(label)
      // if(this.filters.includes(label)){
      //   this.filters = this.filters.filter(f => f !== label)
      // } else {
      //   this.filters.push(label)
      // }
    }
  }
}
</script>
