import Background from './types/background'
import Layer from '../../store/modules/layers/model'

export default class BgVisualFlow extends Background {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Visual flow'
  }

  labels() {
    return ['background']
  }

  icon() {
    return 'fa-solid fa-heart'
  }

  sizeConfig(size) {
    if (size) {
      const width = size.display_dimensions[0]
      const height = size.display_dimensions[1]
      switch (size.aspect_ratio) {
        case '1:1':
          return {
            width,
            height,
            config: {
              bg_visual_flow: {
                direction: 'top',
              }
            }
          }
        case '16:9':
          return {
            width,
            height,
            config: {
              bg_visual_flow: {
                direction: 'right',
              }
            }
          }
        case '9:16':
          return {
            width,
            height,
            config: {
              bg_visual_flow: {
                direction: 'top',
              }
            }
          }
        default:
          return {}
      }
    }
    return {}
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'assetPicker',
          name: 'Image',
          key: 'image',
        },
        [
          {
            type: 'color',
            name: 'Background',
            key: 'color_1',
          },
        ],
        {
          type: 'slider',
          name: 'Image size',
          key: 'image_size',
          min: 0,
          max: 100,
          step: 1,
        },
        {
          type: 'slider',
          name: 'Gradient size',
          key: 'gradient_size',
          min: 0,
          max: 100,
          step: 1,
        },
        {
          type: 'buttonGroup',
          name: 'Image position',
          hide_label: false,
          key: 'direction',
          options: [
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-left',
              value: 'left',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-right',
              value: 'right',
            },
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-up',
              value: 'top',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-down',
              value: 'bottom',
            }
          ]
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            bg_visual_flow: {
              ...baseConfig.config.bg_visual_flow,
              color_1: '#ffffff',
              direction: 'top',
              type: 'radial'
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      width: 1080,
      height: 1080,
      ...this.opts,
      unlinked_properties: ['component.direction'],
      config: {
        component: {
          type: 'bg_visual_flow',
          name: 'Background visual',
        },
        bg_visual_flow: {
          color_1: '#000000',
          direction: 'top',
          image_size: 50,
          image: 'https://cdn.adflow.io/public/d7c0cf54-fdef-4e93-bd96-89d783427128.png',
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
